
body{
  font-family: Arial, Helvetica, sans-serif;
  background-color:rgb(40, 38, 37);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: rgb(198, 50, 51);
  width: 98vw;
  /*height: 1vw;*/
  vertical-align: top;
  
}
img {
  justify-content: center;
  width: 200px;
  padding: 95px;
  margin-bottom: 1px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(195, 168, 119);
  padding: 13px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  width: auto;
  height: auto;
}
input[type="search"]{
  font-size: 23px;
  width: 60%;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  align-items: center;
}
#loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(195, 168, 119);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  width: 30vw;
  height: 38vw;
}

#searchForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(195, 168, 119);
  padding: 1em;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(120, 99, 60);
  width: 80%;
  height: 50%;
  margin: auto;
} 
input[type="name"],
input[type="password"] {
  font-size: 23px;
  width: 43vw;
  padding: 10px;
  margin-top: 9px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  align-items: center;
}
div[type="logOutDescription"]{
  margin-left: 13vw;
  font-size: 4vw;
}
button[type="logOutCancel"],
button[type="logOutConfirm"]{
  width: 20vw;
  background-color: rgb(40, 38, 37);
  margin-right: 2vw;
  margin-left: 2vw;
  color: antiquewhite;
}
button[type="submitButtonTypeTwo"] {
  font-size: 20px;
  padding: 10px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: rgb(195, 168, 119);
  color: antiquewhite;
  border: none;
  margin-top: 4vh;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  cursor: pointer;
  align-items: center;
}
button[type="newPassButton"]{
  font-size: 20px;
  padding: 10px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: rgb(40, 38, 37);
  color: antiquewhite;
  border: none;
  margin-top: 4vh;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  cursor: pointer;
  align-items: center;
}
button[type="submitButtonTypeOne"] {  
  margin-top: 2vw;
  background-color: rgb(40, 38, 37);
  color: antiquewhite;
  border: none;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  cursor: pointer;
  align-items: center;
  width: 30vw;
  height: 4vw;
  font-size: 2vw;
}

button[type="export"],
button[type="logOut"],
button[type="searchPage"],
button[type="homePage"],
button[type="helpPage"] {
  font-size: 16px;
  
  background-color: rgb(198, 50, 51);
  color: antiquewhite;
  border: none;
  margin-top: 0vh;
  cursor: pointer;
}

h1[type="title"] {
  font-size:55px;
  color: antiquewhite;
  font-weight: 100;
}
button[type="logOutConfirm"]:hover,
button[type="logOutCancel"]:hover,
button[type="forgot"]:hover,
button[type="newPassButton"]:hover,
button[type="submitButtonTypeOne"]:hover {
  background-color: red
}
text[type="description"]{
  font-size: 20px;
  color: antiquewhite;
}
input[type="email"]{
  font-size: 23px;
  width: 30%;
  padding: 10px;
  margin-top: 9px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  align-items: center;
}
h1[type="errorDes"]{
  font-size:55px;
}

#indexTable {
  list-style: none;
  padding: 0;
  margin: 0;
}

#indexTable tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(224, 215, 188);
  padding: 1em;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(120, 99, 60);
  margin-bottom: 1em;
}

#indexTable tr:first-child {
  margin-top: 1em;
}

#indexTable tr:last-child {
  margin-bottom: 0;
}

#indexTable tr span {
  font-weight: bold;
}

.searchContainer {
  padding: 3vw;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.searchInput,
.filters input[type="text"] {
  width: 40vw;
  font-size: 16px;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  background-color: rgb(224, 215, 188);
  color: rgb(40, 38, 37);
  margin-right: 0.5em;
}

.filters table {
  border-collapse: collapse;
  width: 100%;
}

.filters th {
  text-align: left;
  background-color: rgb(40, 38, 37);
  color: antiquewhite;
  padding: 1em;
}

.filters td {
  padding: 1em;
}

.filters tbody tr:nth-child(odd) {
  background-color: rgb(240, 235, 220);
}

.filters tbody tr:nth-child(even) {
  background-color: rgb(224, 215, 188);
}

.filters select {
  font-size: 16px;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  background-color: rgb(224, 215, 188);
  color: RGB(40, 38, 37);
  margin-right: 0.5em;
}

.filters button {
  font-size: 16px;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  background-color: rgb(40, 38, 37);
  color: antiquewhite;
  box-shadow: 2px 2px 5px rgb(120, 99, 60);
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  font-size: 18px;
  margin-right: 10px;
  padding: 8px;
  border: none;
  background-color: #e6e6e6;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.pagination button.active {
  background-color: #333;
  color: #fff;
}

/* This would be the for the filter pop out */
#filter-toggle {
  font-size: 20px;
  padding: 10px;
  background-color: rgb(195, 168, 119);
  color: antiquewhite;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
  cursor: pointer;
  margin-bottom: 20px;
}

#filter-panel {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(120, 99, 60);
}

.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.hidden {
  display: none;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

select {
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 5px rgb(120, 99, 60);
  margin-bottom: 10px;
}

.filters {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgb(120, 99, 60);
}

th {
  background-color: rgb(195, 168, 119);
  color: antiquewhite;
}
td[type="header"]{
  font-size:20px;
  font-weight: bold;
  text-decoration-line: underline;
}
td[type="data"]{
  font-size:19px;
}